function Login() {
    
    var ferrerConnect = new FerrerConnect();
    var UserAccess = new UserAccessEntity();
    ferrerConnect.CryptoKey = CONFIG.get('FERRER_CONNECT_CRYPTO_KEY');
    ferrerConnect.AuthenticationId = CONFIG.get('FERRER_CONNECT_AUTH_ID');
    ferrerConnect.urlApi = CONFIG.get('FERRER_CONNECT_URL');
    ferrerConnect.sessionId = CONFIG.get('SESSIONID'); 
    ferrerConnect.url_callback = window.location.href; 
  
    ferrerConnect.EventSource.EventSourceType = "web";
    ferrerConnect.EventSource.EventSourceKey = "trinomia"; 
    ferrerConnect.EventSource.EventSourceItem = location.href;  
    ferrerConnect.CallLogin();
    
}

function Register() {
    
    var ferrerConnect = new FerrerConnect();
    var UserAccess = new UserAccessEntity();
    ferrerConnect.CryptoKey = CONFIG.get('FERRER_CONNECT_CRYPTO_KEY');
    ferrerConnect.AuthenticationId = CONFIG.get('FERRER_CONNECT_AUTH_ID');
    ferrerConnect.urlApi = CONFIG.get('FERRER_CONNECT_URL');
    ferrerConnect.sessionId = CONFIG.get('SESSIONID'); 
    ferrerConnect.url_callback = window.location.href; 

    ferrerConnect.EventSource.EventSourceType = "web";
    ferrerConnect.EventSource.EventSourceKey = "trinomia"; 
    ferrerConnect.EventSource.EventSourceItem = location.href;   
    ferrerConnect.CallRegister();
    
}


function RememberPWD() {
    
    var ferrerConnect = new FerrerConnect();
    var UserAccess = new UserAccessEntity();
    ferrerConnect.CryptoKey = CONFIG.get('FERRER_CONNECT_CRYPTO_KEY');
    ferrerConnect.AuthenticationId = CONFIG.get('FERRER_CONNECT_AUTH_ID');
    ferrerConnect.urlApi = CONFIG.get('FERRER_CONNECT_URL');
    ferrerConnect.sessionId = CONFIG.get('SESSIONID'); 
    ferrerConnect.url_callback = window.location.href; 
      
    ferrerConnect.EventSource.EventSourceType = "web";
    ferrerConnect.EventSource.EventSourceKey = "trinomia"; 
    ferrerConnect.EventSource.EventSourceItem = location.href;   
    ferrerConnect.CallRememberPassword();
    
}

function getkey(){
    var ferrerConnect = new FerrerConnect();
    var UserAccess = new UserAccessEntity();
  
    //var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3ODhhZGY0ZS04Yzc5LTRjZjktOGI4Yy0wOTc3NDAxOTA4NDQiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImUyZmQ5ZDJmLTE2MWMtZjc1My02NGFmLTNmNDFlMjNmY2Q1ZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJ0ZXN0MDIsIHRlc3RpbmciLCJlbWFpbCI6InRlc3RpbmcwMl9jb25uZWN0cGFnZUBob3RtYWlsLmNvbSIsIkRhdGEiOiJ7XCJTdWNjZXNzXCI6dHJ1ZSxcIk1lc3NhZ2VcIjpudWxsLFwiVXNlcklkXCI6XCJlMmZkOWQyZi0xNjFjLWY3NTMtNjRhZi0zZjQxZTIzZmNkNWVcIixcIk9uZUtleVVzZXJJZFwiOlwiZTJmZDlkMmYtMTYxYy1mNzUzLTY0YWYtM2Y0MWUyM2ZjZDVlXCIsXCJPbmVLZXlDb2RlXCI6XCJXRVNNMDE1NzE2NDFcIixcIkZpcnN0TmFtZVwiOlwidGVzdDAyXCIsXCJMYXN0TmFtZVwiOlwidGVzdGluZ1wiLFwiRU1haWxcIjpcInRlc3RpbmcwMl9jb25uZWN0cGFnZUBob3RtYWlsLmNvbVwiLFwiSXNGaXJzdExvZ2luXCI6ZmFsc2UsXCJSZW1lbWJlck1lXCI6ZmFsc2UsXCJBdXRoZW50aWNhdGlvbklkXCI6XCJmNWEzMzY3ZC1jYmVmLTRkOGYtOThiYi02NTA3NzNiZjYzYjBcIixcIlNlY3VyaXR5TGV2ZWxcIjo0LFwiU3RhdHVzSURcIjpcIjNcIixcIklzQWN0aXZlXCI6dHJ1ZSxcIlRlcm1zQWNjZXB0ZWRcIjp0cnVlfSIsImlzcyI6Imh0dHBzOi8vZmVycmVyLmJpc21hcnQuZXMiLCJzdWIiOiJtYWlsdG86dGVzdGluZzAyX2Nvbm5lY3RwYWdlQGhvdG1haWwuY29tIiwiZXhwIjoxNjE4MzA1MjcxLCJhdWQiOiJodHRwczovL2ZlcnJlci5iaXNtYXJ0LmVzIn0.KaRwCB-Z3bpiEmxE6PrAgtF5KUPMPyfat4F5V1Hl2OA";
    var key = document.getElementById("fkey").value;
    ferrerConnect.CryptoKey = CONFIG.get('FERRER_CONNECT_CRYPTO_KEY');
    ferrerConnect.AuthenticationId = CONFIG.get('FERRER_CONNECT_AUTH_ID');
    ferrerConnect.urlApi = CONFIG.get('FERRER_CONNECT_URL');
    ferrerConnect.sessionId = CONFIG.get('SESSIONID'); 
    ferrerConnect.url_callback = window.location.href; 

    UserAccess = ferrerConnect.GetUserAccess(key);
    
    document.getElementById("keyresult").innerHTML=JSON.stringify(UserAccess);
    console.log(UserAccess);
}

function setEvent (eventName, categoryValue = undefined, loc = location.href, value = undefined){

    var ferrerConnect = new FerrerConnect();
    var event = new EventEntity();
    event.authId = CONFIG.get('FERRER_CONNECT_AUTH_ID');
    event.oneKeyUserId = CONFIG.get('FERRER_CONNECT_USER_ID');


    //var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3ODhhZGY0ZS04Yzc5LTRjZjktOGI4Yy0wOTc3NDAxOTA4NDQiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImUyZmQ5ZDJmLTE2MWMtZjc1My02NGFmLTNmNDFlMjNmY2Q1ZSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJ0ZXN0MDIsIHRlc3RpbmciLCJlbWFpbCI6InRlc3RpbmcwMl9jb25uZWN0cGFnZUBob3RtYWlsLmNvbSIsIkRhdGEiOiJ7XCJTdWNjZXNzXCI6dHJ1ZSxcIk1lc3NhZ2VcIjpudWxsLFwiVXNlcklkXCI6XCJlMmZkOWQyZi0xNjFjLWY3NTMtNjRhZi0zZjQxZTIzZmNkNWVcIixcIk9uZUtleVVzZXJJZFwiOlwiZTJmZDlkMmYtMTYxYy1mNzUzLTY0YWYtM2Y0MWUyM2ZjZDVlXCIsXCJPbmVLZXlDb2RlXCI6XCJXRVNNMDE1NzE2NDFcIixcIkZpcnN0TmFtZVwiOlwidGVzdDAyXCIsXCJMYXN0TmFtZVwiOlwidGVzdGluZ1wiLFwiRU1haWxcIjpcInRlc3RpbmcwMl9jb25uZWN0cGFnZUBob3RtYWlsLmNvbVwiLFwiSXNGaXJzdExvZ2luXCI6ZmFsc2UsXCJSZW1lbWJlck1lXCI6ZmFsc2UsXCJBdXRoZW50aWNhdGlvbklkXCI6XCJmNWEzMzY3ZC1jYmVmLTRkOGYtOThiYi02NTA3NzNiZjYzYjBcIixcIlNlY3VyaXR5TGV2ZWxcIjo0LFwiU3RhdHVzSURcIjpcIjNcIixcIklzQWN0aXZlXCI6dHJ1ZSxcIlRlcm1zQWNjZXB0ZWRcIjp0cnVlfSIsImlzcyI6Imh0dHBzOi8vZmVycmVyLmJpc21hcnQuZXMiLCJzdWIiOiJtYWlsdG86dGVzdGluZzAyX2Nvbm5lY3RwYWdlQGhvdG1haWwuY29tIiwiZXhwIjoxNjE4MzA1MjcxLCJhdWQiOiJodHRwczovL2ZlcnJlci5iaXNtYXJ0LmVzIn0.KaRwCB-Z3bpiEmxE6PrAgtF5KUPMPyfat4F5V1Hl2OA";
    ferrerConnect.CryptoKey = CONFIG.get('FERRER_CONNECT_CRYPTO_KEY');
    ferrerConnect.AuthenticationId = CONFIG.get('FERRER_CONNECT_AUTH_ID');
    ferrerConnect.urlApi = CONFIG.get('FERRER_CONNECT_URL');

    event.eventName = eventName; 
    event.userId = CONFIG.get('FERRER_CONNECT_ONEKEY_ID');
    //EventSource
    event.eventSourceType = "web";
    event.eventSourceKey = "trinomia";
    event.eventSourceItem = loc;
    //SourceInfo
    event.sessionId = CONFIG.get('SESSIONID');
    // event.ipAddress="182.32.23.1";
    // event.deviceType= "Phone";
    // event.deviceModel= "Huawei";
    // event.browserName= "Mozilla";
    // event.operatingSystem="Windows 10 Pro";
    // event.operatingSystemVersion= "19041.685";
    // event.httpReferral= "";
    //UserFace
    event.userFacetId= CONFIG.get('FERRER_CONNECT_EMAIL');
    event.facetUID = CONFIG.get('FERRER_CONNECT_USER_ID');
    //event categories
    // categoryKey:
    //   "TipoContenido",
    //   "TitleTag"
    if(typeof categoryValue != "undefined"){
        var categore = new eventCategories();
        categore.categoryKey = "TipoContenido";
        categore.categoryValues.push(categoryValue);
        event.eventCategories.push(categore);
    }
    
    var categore2 = new eventCategories();
    categore2.categoryKey = "TitleTag";
    if(typeof value != "undefined"){
        categore2.categoryValues.push(value);
    }
    categore2.categoryValues.push(loc);
    event.eventCategories.push(categore2);
    console.log(event);
    //Event devuelve una promesa
    ferrerConnect.Event(event).then(response => {
        console.log(response);
    });;
    
  
}

function Logout() {
    document.cookie = 'FerrerConnectAuth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.location.href="/";
};