
function contactSuccess(){
    $.fancybox.close();
    setEvent(setEvent('contact'));
    notifications.show("El formulario se ha enviado correctamente. En breve contactaremos contigo.", 
    notifications.statuses.SUCCESS, function() {
        location = location.href;
    });
}

function landingformdownloadStep1(response) {
    if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
        gtag('event',  'EVENT_LANDING_NEPTUNO_submit_form_step_1', {
          'value': 1
        });
    }
    if(response.toDownloadPage == true){
        landingformdownloadok(true);
    }else{
        $('.form-wrapper--step-1').fadeOut(function() {
            $('.form-wrapper--step-2').fadeIn();
        });
    }
}

function landingformdownloadstep2ok(){
    landingformdownloadok(false);
}

function landingformdownloadok(fromstep1) {
    if(!fromstep1){
        if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
            gtag('event',  'EVENT_LANDING_NEPTUNO_submit_form_step_2', {
              'value': 1
            });
        }
    }
    $('a#download').attr('href', 'common/project/pdf/' + $('a#download').data('fileName') + '.pdf');
    gtmt('v_pageview', {
        'page_name':'/registro_completo',
        'title':'Trinomia | Registro Completo',
        'flujo':'registro',
        'seccion':'completo',
        'profesional':'si',
        'activo':'landing/secure',
    });
    $("a#downloadUrl").on('click', function(event){
        event.preventDefault();
        if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
            gtag('event',  'EVENT_LANDING_SECURE_boton_descarga_2', {
              'value': 1
          });
        }
        gtmt('interaction', {
            'page_name':'/registro_completo',
            'flujo':'registro',
            'seccion':'completo',
            'categoria':'registro',
            'accion':'clic',
            'copy':'acceder',
            'profesional':'si',
            'activo':'landing/secure',
        });
        window.open($(this).attr("href"), "_blank");
    });
    $('.fancybox-content .box__content__form').fadeOut(function() {
        $('.fancybox-content .box__content__feedback').fadeIn();
    });
}

function landingformcasosclinicosStep1(response) {
    if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
        gtag('event',  'EVENT_LANDING_CASOS_CLINICOS_submit_form_step_1', {
          'value': 1
        });
    }
    if(response.toDownloadPage == true){
        landingformcasosclinicosok(true);
    }else{
        $('.form-wrapper--step-1').fadeOut(function() {
            $('.form-wrapper--step-2').fadeIn();
        });
    }
}

function landingformcasosclinicosstep2ok(){
    landingformcasosclinicosok(false);
}

function landingformcasosclinicosok(fromstep1) {
    if(!fromstep1){
        if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
            gtag('event',  'EVENT_LANDING_CASOS_CLINICOS_submit_form_step_2', {
              'value': 1
            });
        }
    }

    $('.fancybox-content .box__content__form').fadeOut(function() {
        $('.fancybox-content .box__content__feedback').fadeIn();
    });

    $('a#download').click(function(e) {
        e.preventDefault();
        $.fancybox.close();
        $.fancybox.open({
            src  : 'https://aulamedia.net/ccu/',
            type : 'iframe'
        });
    });
}


function landingSecureformdownloadStep1(response) {
    if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
        gtag('event',  'EVENT_LANDING_SECURE_submit_form_step_1', {
          'value': 1
        });
    }
    gtmt('interaction', {
        'page_name':'/registro_email',
        'flujo':'registro',
        'seccion':'email',
        'categoria':'registro',
        'accion':'formulario enviado',
        'copy':'siguiente',
        'profesional':'si',
        'activo':'landing/secure',
    });
    if(response.toDownloadPage == true){
        landingformdownloadok(true);
    }else{
        $('.form-wrapper--step-1').fadeOut(function() {
            $('.form-wrapper--step-2').fadeIn();
            gtmt('v_pageview', {
                'page_name':'/registro_nombre',
                'title':'Trinomia | Registro Nombre',
                'flujo':'registro',
                'seccion':'nombre',
                'profesional':'si',
                'activo':'landing/secure',
            });
        });
    }
}

function landingSecureformdownloadstep2ok(){
    gtmt('interaction', {
        'page_name':'/registro_nombre',
        'flujo':'registro',
        'seccion':'nombre',
        'categoria':'registro',
        'accion':'formulario enviado',
        'copy':'enviar',
        'profesional': 'si',
        'activo':'landing/secure',
    });
    landingSecureformdownloadok(false);
}

function landingSecureformdownloadok(fromstep1) {
    if(!fromstep1){
        if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
            gtag('event',  'EVENT_LANDING_SECURE_submit_form_step_2', {
              'value': 1
            });
        }
    }
    gtmt('v_pageview', {
        'page_name':'/registro_completo',
        'title':'Trinomia | Registro Completo',
        'flujo':'registro',
        'seccion':'completo',
        'profesional':'si',
        'activo':'landing/secure',
    });

    $("a#downloadUrl").on('click', function(event){
        event.preventDefault();
        if(typeof CONFIG.get('gtag_code') !== 'undefined' && CONFIG.get('gtag_code').length) {
            gtag('event',  'EVENT_LANDING_SECURE_boton_descarga_2', {
              'value': 1
          });
        }
        gtmt('interaction', {
            'page_name':'/registro_completo',
            'flujo':'registro',
            'seccion':'completo',
            'categoria':'registro',
            'accion':'clic',
            'copy':'acceder',
            'profesional':'si',
            'activo':'landing/secure',
        });
        window.open($(this).attr("href"), "_blank");
    });

    $('.fancybox-content .box__content__form').fadeOut(function() {
        $('.fancybox-content .box__content__feedback').fadeIn();
    });
}


function contactError(response){
    jQuery.each(response.specificData, function( i, field ) {
        $('#'+ field).parent().addClass('error');
        if (field=="email") {
            gtmt('incidence', {
                'page_name':'/registro_email',
                'flujo':'registro',
                'seccion':'email',
                'categoria':'registro',
                'accion':'incidencia',
                'copy':'error en email',
                'profesional':'si',
                'activo': 'landing/secure',
            });
        } else if (field=="termsAcceptance") {
            gtmt('incidence', {
                'page_name':'/registro_email',
                'flujo':'registro',
                'seccion':'email',
                'categoria':'registro',
                'accion':'incidencia',
                'copy':'error en aceptar politica',
                'profesional':'si',
                'activo': 'landing/secure',
            });
        } else if (field=="name") {
            gtmt('incidence', {
                'page_name':'/registro_nombre',
                'flujo':'registro',
                'seccion':'nombre',
                'categoria':'registro',
                'accion':'incidencia',
                'copy':'error en nombre',
                'profesional':'si',
                'activo':'landing/secure'
            });    
        } else if (field=="surname") {
            gtmt('incidence', {
                'page_name':'/registro_nombre',
                'flujo':'registro',
                'seccion':'nombre',
                'categoria':'registro',
                'accion':'incidencia',
                'copy':'error en apellido',
                'profesional':'si',
                'activo':'landing/secure'
            });    
        }
    });
}

function recaptchaOk(token) {
        $('<input>').attr({
        type: 'hidden',
        name: 'g-recaptcha-token',
        value: token
        }).appendTo('form#contactForm');
}

if ($('.landing--secure').length) {
    var download = getUrlParameter('download');
    if (download == 'direct') {
        $('.landing--secure #landingSecureDirectDownload').show();
    } else {
        $('.landing--secure #landingSecureFormDownload').show();
    }
    console.log('download: '+download);
    console.log(download == 'direct');
}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
}
