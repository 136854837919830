
	$('.btn-reply').on('click', function(e) {
		e.preventDefault();

		var $replyForm = $('<div>' + $('#comment-reply').html() + '</div>');

		$('#form--reply').parent().remove();

		$replyForm.find('[name=objectId]').val($(this).data('id'));
		$replyForm.insertAfter($(this).closest('.comment__button'));
		initializer.processQueue($replyForm);

	});

	if($('#form--reply').length) {
		var $form = $('#form--reply');
		var $recaptcha = $form.find('.g-recaptcha');

		$recaptcha.attr('id', 'form--reply-recaptcha');

		grecaptcha.render('form--reply-recaptcha', $recaptcha.data());
	}

	if($('#comments-async-url').length && typeof commentsUrl != 'undefined') {
		$('.comment__item').load(commentsUrl, function(data){
			if(window.location.hash.length) {
				gentlyScrollTo($(window.location.hash), window.location.hash);
			}
		});
	}

	var commentSaveOk = function(data, $form, responseType) {
		notifications.show(data.message, notifications.statuses.SUCCESS, function(){
			utils.loadPage(window.location.origin + window.location.pathname, '#comment-' + data.item.id);
		});
	};

